@if ($buttonLabel()) {
  <myt-button [type]="buttonType" [size]="buttonSize" [matMenuTriggerFor]="menu" class="md:menu__button hidden md:block">
    {{ $buttonLabel() }}
    <myt-icon name="expand_more" [size]="iconSize" class="myt-button__suffix" />
  </myt-button>
  <myt-button [type]="buttonTypes.Icon" class="text-white md:hidden" [matMenuTriggerFor]="menu">
    <myt-icon name="menu" [size]="iconSize" />
  </myt-button>
} @else if ($textLabel()) {
  <span class="text-title cursor-pointer underline" [matMenuTriggerFor]="menu" [ngClass]="$textLabelCssClasses()">
    {{ $textLabel() }}
  </span>
}

<mat-menu #menu="matMenu" xPosition="before" [class]="'menu__panel ' + $menuPanelCssClasses()">
  @for (item of $items(); track item.label) {
    @if (item.externalLink) {
      <a mat-menu-item [href]="item.externalLink">{{ item.label }}</a>
    } @else if (item.link) {
      <button mat-menu-item [routerLink]="item.link">{{ item.label }}</button>
    } @else {
      <div [ngClass]="{ 'menu__item-with-icon': item.selected }">
        <button mat-menu-item (click)="onItemSelect(item.label)">
          <span [ngClass]="{ 'text-accent-800': item.selected }">{{ item.label }} </span>
          @if (item.selected) {
            <mat-icon class="!text-accent-800">check</mat-icon>
          }
        </button>
      </div>
    }
  }
</mat-menu>
