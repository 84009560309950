import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { ButtonSize } from '../button/button-size.enum';
import { ButtonType } from '../button/button-type.enum';
import { ButtonsComponent } from '../button/button.component';
import { IconSize } from '../icon/icon-size.enum';
import { IconsComponent } from '../icon/icon.component';
import { MenuItem } from './menu.interface';

@Component({
  selector: 'myt-menu',
  standalone: true,
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenuModule, ButtonsComponent, IconsComponent, RouterLink, MatIconModule, NgClass],
})
export class MenuComponent {
  readonly buttonTypes = ButtonType;
  readonly buttonType = ButtonType.StrokedWhite;
  @Output() itemSelect = new EventEmitter<MenuItem['label']>();
  readonly buttonSize = ButtonSize.Medium;
  readonly iconSize = IconSize.Small;

  readonly $buttonLabel = input<string>('', { alias: 'buttonLabel' });
  readonly $textLabel = input<string>('', { alias: 'textLabel' });
  readonly $items = input.required<MenuItem[]>({ alias: 'items' });
  readonly $textLabelCssClasses = input<string>('', { alias: 'textLabelCssClasses' });
  readonly $menuPanelCssClasses = input<string>('', { alias: 'menuPanelCssClasses' });

  onItemSelect(label: MenuItem['label']): void {
    this.itemSelect.emit(label);
  }
}
